import React from 'react'
import NavigationBar from '../NavigationBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Add_new_order_form from '../Forms/add_new_order_form';
import { Form,Button } from 'react-bootstrap';
import Item from '../add_new_order_components/item';
// import plus from '../Images/plus-symbol-button.png';
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from '../axios';
import { useLocation } from 'react-router-dom';
import { set } from 'rsuite/esm/internals/utils/date';
import { useNavigate } from 'react-router-dom';


function Add_new_order() {
    const [isloading, setIsLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [update_mode, set_update_mode] = useState(false);
    const [itemCount, setitemCount] = useState(1);

    const [orderItems, setOrderItems] = useState([]);
    
    useEffect(() => {
        if (location.state && location.state.orderId) {
            const orderId= location.state.orderId

            axios.get(`${window.backendUrl}/get_order/${orderId}`)
            .then(response => {
                // console.log('Order fetched successfully:', response.data);
                setOrderData(response.data.orderData);
                setOrderItems(response.data.orderItems);
                set_update_mode(true);//dont forget to set this when update button is clicked
            })
            .catch(error => {
                console.error('There was an error fetching the order:', error);
                // Handle any additional error actions here
            });
        }else {
            axios.get(`${window.backendUrl}/get_new_job_number`)
                .then((response) => {
                    setOrderData(prevState => ({
                        ...prevState,
                        job_number: response.data.NextJobNumber + '/' + new Date().getFullYear() % 100 + '-N',
                        order_date: new Date(Math.floor(new Date().getTime() / 1000) * 1000).toLocaleString('sv-SE', { timeZone: 'Asia/Dhaka' }).split(' ')[0],
                        delivery_date : new Date(new Date(Math.floor(new Date().getTime() / 1000) * 1000).setDate(new Date().getDate() + 3)).toLocaleString('sv-SE', { timeZone: 'Asia/Dhaka' }).split(' ')[0]
                    }));
                })
                .catch((error) => {
                    console.log(error);
                });
        }
      }, [location.state]);


    useEffect(() => {
        if (orderItems.length === 0) {
          setitemCount(1);
        } else {
          setitemCount(orderItems.length);
        }
    }, [orderItems.length]);

    const handleSubmit = () => {
        // const { job_number, order_date, delivery_date, party_name, buyer_name} = orderData;
    
        // // Check if any required field in orderData is empty
        // if (!job_number || !order_date || !delivery_date || !party_name || !buyer_name) {
        //     alert('Please fill all the missing values 1');
        //     return;
        // }
    
        // // Check if orderItems is empty
        // if (orderItems.length === 0) {
        //     alert('Please fill all the missing values');
        //     return;
        // }
    
        // // Check if any field in orderItems, specification, or gsm is empty or blank
        // for (const item of orderItems) {
        //     if (!item.itemName) {
        //         alert('Please fill all the missing values');
        //         return;
        //     }
    
        //     // Check if specification exists
        //     if (!item.specification || item.specification.length === 0) {
        //         alert('Please fill all the missing values');
        //         return;
        //     }
    
        //     for (const spec of item.specification) {
        //         if (!spec.specName) {
        //             alert('Please fill all the missing values');
        //             return;
        //         }
    
        //         // Check if gsm exists
        //         if (!spec.gsm || spec.gsm.length === 0) {
        //             alert('Please fill all the missing values');
        //             return;
        //         }
    
        //         for (const gsm of spec.gsm) {
        //             const requiredFields = ['length', 'width', 'height', 'grade', 'order_quantity', 'unit_price'];
        //             for (const field of requiredFields) {
        //                 if (!gsm[field]) {
        //                     alert('Please fill all the missing values');
        //                     return;
        //                 }
        //             }
        //         }
        //     }
        // }
    
        // Construct the data payload to send to the server
        const data = {
            orderData: orderData,
            orderItems: orderItems
        };
        setIsLoading(true);
        // axios.post(`${window.backendUrl}/insert_new_order`, data)
        //     .then(response => {
        //         alert('Order submitted successfully.');
        //         navigate('/manage-orders');
        //     }).catch(error => {
        //         alert('There was an error submitting the order. Please try again.');
        //     });
        axios.post(`${window.backendUrl}/insert_new_order`, data)
            .then(response => {
                if (response.status === 200) {
                    alert('Order submitted successfully.');
                    navigate('/manage-orders');
                }
            })
            .catch(error => {
                alert('There was an error submitting the order. Please try again.');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    
    
    const handleUpdate = () => {
        // const { job_number, order_date, delivery_date, party_name, buyer_name } = orderData;
    
        // // Check if any required field in orderData is empty
        // if (!job_number || !order_date || !delivery_date || !party_name || !buyer_name) {
        //     alert('Please fill all the missing values 1');
        //     return;
        // }
    
        // // Check if orderItems is empty
        // if (orderItems.length === 0) {
        //     alert('Please fill all the missing values');
        //     return;
        // }
    
        // // Check if any field in orderItems, specification, or gsm is empty or blank
        // for (const item of orderItems) {
        //     if (!item.itemName) {
        //         alert('Please fill all the missing values');
        //         return;
        //     }
    
        //     // Check if specification exists
        //     if (!item.specification || item.specification.length === 0) {
        //         alert('Please fill all the missing values');
        //         return;
        //     }
    
        //     for (const spec of item.specification) {
        //         if (!spec.specName) {
        //             alert('Please fill all the missing values');
        //             return;
        //         }
    
        //         // Check if gsm exists
        //         if (!spec.gsm || spec.gsm.length === 0) {
        //             alert('Please fill all the missing values');
        //             return;
        //         }
    
        //         for (const gsm of spec.gsm) {
        //             const requiredFields = ['grade', 'order_quantity', 'unit_price'];
        //             for (const field of requiredFields) {
        //                 if (!gsm[field]) {
        //                     alert('Please fill all the missing values');
        //                     return;
        //                 }
        //             }
        //         }
        //     }
        // }

        // Construct the data payload to send to the server
        const data = {
            orderData: orderData,
            orderItems: orderItems
        };

        // Make the POST request to the server
        axios.post(`${window.backendUrl}/update_order`, data)
            .then(response => {
                console.log('Order submitted successfully:', orderItems);
                navigate('/manage-orders')
            })
            .catch(error => {
                console.error('There was an error submitting the order:', error);
                // Handle any additional error actions here
            });
    };

    const handleCancel = () => {
        axios.post(`${window.backendUrl}/cancel_order/${orderData.orderid}`)
            .then(response => {
                console.log('Order cancelled successfully:', orderItems);
                navigate('/manage-orders')
            })
            .catch(error => {
                console.error('There was an error cancelling the order:', error);
                // Handle any additional error actions here
        });
    }

    const [orderData, setOrderData] = useState({
        job_number: "",
        order_date: "",
        delivery_date: "",
        party_name:"",
        buyer_name:"",
        style:"",
        attention:"",
        delivery_station:"",
        lpd_po_no:"",
        company_bank:"",
        company_bank_id: "",
        credit_days:"",
        concern_email:"",
        team_name:"",
        currency:"$",
        unit_price_per:"Square Meter",
        allowanceLW: 6,
        allowanceWH: 4,
        allowanceLength: 2,
        allowanceWidth: 2,
        note:"",
    });

    // const handleOrderDataChange = (e) => {
    //     const { name, value } = e.target;
    //     setOrderData(prevData => ({
    //         ...prevData,
    //         [name]: value
    //     }));
    // };
    const handleOrderDataChange = (e) => {
        const { name, value } = e.target;
        
        if (name === "company_bank") {
            const [id, bankName] = value.split('|');
            console.log('handle order data change', id, bankName);
            setOrderData(prevData => ({
                ...prevData,
                company_bank_id: id,
                company_bank: bankName
            }));
        } else {
            setOrderData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };
    

    const handleOrderItemChange = (e) => {
        const { name, value, dataset } = e.target;
        if (name=='itemName' || name=='style' || name=='PO'){
            const item_id = parseInt(dataset.item_id, 10);
        
            // Ensure the item at item_id exists and is an object
            const updatedItems = [...orderItems];
            if (!updatedItems[item_id]) {
                updatedItems[item_id] = {};
            }
            if (name == 'itemName'){
                const [item_name, item_category] = value.split('|');
                updatedItems[item_id]['itemName'] = item_name;
                updatedItems[item_id]['category'] = item_category;
                setOrderItems(updatedItems);
            }else{
                updatedItems[item_id][name] = value;
                setOrderItems(updatedItems);
            }
        }else if (name == 'specName'){
            const item_id = parseInt(dataset.item_id, 10);
            const spec_id = parseInt(dataset.spec_id, 10);

            // Ensure the item at item_id exists and is an object
            const updatedItems = [...orderItems];
            if (!updatedItems[item_id]) {
                updatedItems[item_id] = {};
            }

            if (!('specification' in updatedItems[item_id])){
                updatedItems[item_id]['specification'] = []
            }

            if (!updatedItems[item_id]['specification'][spec_id]) {
                updatedItems[item_id]['specification'][spec_id]={}
            }

            updatedItems[item_id]['specification'][spec_id][name]= value
            setOrderItems(updatedItems);
            // console.log(updatedItems)
        }else if (name== 'unit' || name=='length'|| name=='width' ||name == 'height' 
            || name=='grade' || name=='order_quantity' || name=='unit_price' || name=='addi_work' || name=='over_invoice'|| name=='notes' || name=='LinerOuter' || name=='LinerInner' || name=='BFlute1' || name=='BFlute2' || name=='CFlute' || name=='PMedium1' || name=='PMedium2'){
            const item_id = parseInt(dataset.item_id, 10);
            const spec_id = parseInt(dataset.spec_id, 10);
            const gsm_id = parseInt(dataset.gsm_id, 10);

            // Ensure the item at item_id exists and is an object
            const updatedItems = [...orderItems];
            if (!updatedItems[item_id]) {
                updatedItems[item_id] = {};
            }

            if (!('specification' in updatedItems[item_id])){
                updatedItems[item_id]['specification'] = []
            }

            if (!updatedItems[item_id]['specification'][spec_id]) {
                updatedItems[item_id]['specification'][spec_id]={}
            }

            if (!('gsm' in updatedItems[item_id]['specification'][spec_id])){
                updatedItems[item_id]['specification'][spec_id]['gsm'] = []
            }

            if (!updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id]) {
                updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id]={}
            }

            updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id][name]= value

            const convertToCM = (value, unit) => {
                const conversionFactors = {
                  Inch: 2.54,
                  MM: 0.1,
                  Yard: 91.44,
                  CM: 1,
                };
                const convertedValue = value * (conversionFactors[unit] || 1);
                return parseFloat(convertedValue.toFixed(2));
            };   

            if (name == 'length' || name == 'width' || name == 'height' || name == 'unit'){
                let length = parseFloat(updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id]['length']) || 0;
                let width = parseFloat(updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id]['width']) || 0;
                let height = parseFloat(updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id]['height']) || 0;

                length = convertToCM(length, updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id]['unit']);
                width = convertToCM(width, updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id]['unit']);
                height = convertToCM(height, updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id]['unit']);


                if (updatedItems[item_id]['category'] == 'Top Bottom' || updatedItems[item_id]['category'] == 'Divider'){
                    updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id]['square_meter'] = ((length*width) / 10000)
                }else{
                    updatedItems[item_id]['specification'][spec_id]['gsm'][gsm_id]['square_meter'] = ((length + width + 6) * (width + height + 4)) / 5000;
                }
            }
            setOrderItems(updatedItems);
        }
    };
    

    return (
        <Container fluid >
            <NavigationBar />
            <Container fluid style={{marginTop:'20px'}}>
                <Row>
                    <Col xs={12}>
                        <Add_new_order_form handleOrderDataChange={handleOrderDataChange} orderData={orderData}/>
                    </Col>
                </Row>
                <Row style={{marginTop:'10px'}}>
                    {/* <Item updateItemCount={updateItemCount} /> */}
                        {/* Render 'Item' component based on 'itemCount' */}
                        {Array.from({ length: itemCount }, (_, index) => (
                        <Item key={index} party_name={orderData.party_name} item_id={index} itemCount={itemCount} setitemCount={setitemCount} orderItems={orderItems} setOrderItems={setOrderItems} handleOrderItemChange={handleOrderItemChange} />
                    ))}
                </Row>
            </Container>
            <Row className='mt-4 mb-4'>
                    {/* <Col className="text-end me-5">
                        {update_mode ? (<Button variant="primary" onClick={handleUpdate}>Update</Button>):
                        (<Button variant="primary" onClick={handleSubmit}>Save</Button>)}
                    </Col> */}
                    <Col className="text-end me-5">
                        {update_mode ? (
                            <div className='d-flex justify-content-between'>
                                <Button style={{marginLeft:'50px'}} variant="danger" onClick={handleCancel}>Cancel Order</Button>
                                <Button variant="primary" onClick={handleUpdate}>Update</Button>
                            </div>
                        ) : (
                            <Button variant="primary" disabled={isloading} onClick={handleSubmit}>Save</Button>
                            // <Button variant="primary"  onClick={handleSubmit}>Save</Button>
                        )}
                    </Col>
            </Row>
        </Container>
  )
}
{/* <Image src="/Images/plus-symbol-button.png" alt="plus" style={{ width: '10px', height: '10px' }}/> */}
export default Add_new_order