import React from 'react';
import { convertNumberToWords, formatNumberWithCommas } from '../../utilities';

const BillOfExchange1 = ({commercial_info}) => {
  
  return (
    <>
    <div style={{ textAlign: 'center', fontSize:'14px' }}>
      <h1 style={{ textDecoration: 'underline', fontWeight: 'bolder', marginTop: '10px', fontSize:'25px', color:'blue' }}>
        BILL OF EXCHANGE
        {/* <i  style={{ fontSize: '15px', visibility: isprint ? 'visible' : 'hidden' }} className="print-button fa fa-print" onClick={() => window.open(`/commercial-pdf-viewer/56`, '_blank')}></i> */}
      </h1>
      <h1 className='challan_header' style={{ color: '#000', fontSize:'25px', lineHeight:'20px' }}>1st</h1>
    </div>
    {/* <div style={{ textAlign: 'center' }}>
      <h1 style={{ color: '#000', fontSize:'25px' }}>1st</h1>
    </div> */}
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td style={{ textAlign: 'left' }}>
              INVOICE NO.<strong>{commercial_info.invoiceNo}</strong>
            </td>
            <td style={{ textAlign: 'right' }}>
              &nbsp;
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style={{ textAlign: 'left', padding: '10px 0' }}>
      EXCHANGE FOR <strong>USD = {formatNumberWithCommas(commercial_info.totalPrice)}</strong>
    </div>
    <div style={{ textAlign: 'left', padding: '10px 0' }}>
      DRAWN UNDER DOCUMENTARY CREDIT NO. <strong>{commercial_info.lcNo}</strong>, DATE : <strong>{new Date(commercial_info.lcDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</strong>
    </div>
    <div style={{ textAlign: 'left', padding: '10px 0' }}>
      DRAWN ON : <strong>{commercial_info.partyBank}</strong>
    </div>
    <div style={{ textAlign: 'left', padding: '10px 0' }}>
      AT <strong>{commercial_info.duration == 0 ? 'SIGHT ' : `${commercial_info.duration} days `}</strong>
      FROM THE DATE OF ACCEPTANCE OF THIS <strong>FIRST</strong> OF DRAFT (SECOND OF THE SAME TENOR) 
      PAY TO THE ORDER OF <strong>{commercial_info.companyBank}</strong>, {commercial_info.companyBankAddress}
      <br />
      THE SAME OF IN WORD USD <strong>{convertNumberToWords(commercial_info.totalPrice)}</strong>.
      <br />
      VALUE RECEIVED UNDER Export LC/SC No. <strong>{commercial_info.contractNo}</strong> DATE: <strong>{new Date(commercial_info.contractDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}</strong>
    </div>
    <div style={{ textAlign: 'left', padding: '10px 15px 0 0' }}>
      ISSUING BANK BIN NO. <strong>{commercial_info.partyBankBin}</strong><br />
      BENEFICIARY'S VAT REG. NO. 18051014507, BIN NO. 000353482-0102, H.S CODE NO. 4819.10.00
    </div>
    <div style={{ textAlign: 'left' }}>
    PROFORMA INVOICE NO. {commercial_info.piInfo.map((pi, index) => <span key={pi.piId}><strong>PI/{pi.jobNumber}  </strong> DATE:<strong> {new Date(pi.createdDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '.')}</strong>{index < commercial_info.piInfo.length - 1 ? ', ' : ''}</span>)}<br />

    </div>
    <div style={{ textAlign: 'left' }}>
      TO<br />
      <strong>{commercial_info.partyBank}</strong><br />
      {/* PLC., MAIN BRANCH, 63, DILKUSHA C/A,<br />
      DHAKA-1000, BANGLADESH.<br /> */}
      {commercial_info.partyBankAddress.split(',').reduce((acc, word, index) => {
        if (index > 0 && index % 4 === 0) acc.push(<br key={index} />);
        acc.push(word.trim());
        return acc;
      }, []).map((item, index) => (
        <span key={index}>
          {item}
          {index < commercial_info.partyBankAddress.split(',').length - 1 ? ',' : ''}
        </span>
      ))}
      <br/>
      A/C <strong>{commercial_info.partyName}</strong>
    </div>
    <div style={{ textAlign: 'left' }}>
      <p><br /><br /></p>
      <strong>SIGNED BY<br /></strong>
    </div>
    </>
    );
};

export default BillOfExchange1;