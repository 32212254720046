import "rsuite/dist/rsuite.css";
import React, { useState, useEffect, useRef } from 'react';
import NavigationBar from '../NavigationBar';
import axios from '../axios';
import { Form, Container, Row, Col, Table, Button } from 'react-bootstrap';
import {renderPagination,renderAdvancedPagination} from '../utilities';
import { PPExcelDateModal} from "../utilities";
import { DateRangePicker } from 'rsuite';
// import './manage_orders.css';
import { useNavigate } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import { useCallback } from 'react';
import debounce from 'lodash/debounce';

function ProductionPlanning() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [orders, setOrders] = useState([]);
    const [pp_orders, set_pp_orders] = useState([]);
    const [pp_date,set_pp_date]=useState('');
    // const [add_to_pp_click, set_add_to_pp_click] = useState(false);
    const [setting_and_roll, set_setting_and_roll] = useState({});
    const [new_pp_quantities,set_new_pp_quantities] = useState({});
    const [dateRange, setDateRange] = useState([]);
    const [searchClick, setSearchClick] = useState(false);
    const [filters, setFilters] = useState({
        teamname: '',
        partyname: '',
        buyername: '',
        itemname: '',
        specification: '',
        category: ''
    });
    const [job_number, set_job_number] = useState('');
    const [style, set_style] = useState('');

    const [team_options, set_team_options]=useState([]);
    const [party_options, set_party_options]=useState([]);
    const [buyer_options, set_buyer_options]=useState([]);
    const [item_options, set_item_options]=useState([]);
    const [spec_options, set_spec_options]=useState(['ply 3','ply 5','ply 7']);
    const [category_options, set_category_options]=useState([])
    // const roll_size_options=[
    //     { value: 28, text: "28 (2/Sheet)" },
    //     { value: 30, text: "30 (2/Sheet)" },
    //     { value: 32, text: "32 (2/Sheet)" },
    //     { value: 34, text: "34 (2/Sheet)" },
    //     { value: 36, text: "36 (2/Sheet)" },
    //     { value: 38, text: "38 (2/Sheet)" },
    //     { value: 40, text: "40 (2/Sheet)" },
    //     { value: 42, text: "42 (3/Sheet)" },
    //     { value: 44, text: "44 (3/Sheet)" },
    //     { value: 46, text: "46 (3/Sheet)" },
    //     { value: 48, text: "48 (3/Sheet)" },
    //     { value: 50, text: "50 (3/Sheet)" },
    //     { value: 52, text: "52 (3/Sheet)" },
    //     { value: 54, text: "54 (3/Sheet)" },
    //     { value: 56, text: "56 (4/Sheet)" },
    //     { value: 58, text: "58 (4/Sheet)" },
    //     { value: 60, text: "60 (4/Sheet)" },
    //     { value: 62, text: "62 (4/Sheet)" },
    //     { value: 64, text: "64 (4/Sheet)" },
    //     { value: 66, text: "66 (4/Sheet)" },
    //     { value: 68, text: "68 (4/Sheet)" }
    // ]
    // const [showModal, setShowModal] = useState(false);
    // const [selectedDate, setSelectedDate] = useState(0);
    const setting_options =[1,2,3,4,5,6]
    useEffect(() => {
        fetch_dropdown_options();
    }, []);

    const fetch_dropdown_options = async () => {
        try {
            const response1 = await axios.get(`${window.backendUrl}/get_all_teams`);
            const response2 = await axios.get(`${window.backendUrl}/get_all_parties`);
            const response3 = await axios.get(`${window.backendUrl}/get_all_buyers`);
            const response4 = await axios.get(`${window.backendUrl}/get_all_items`);
            const response5 = await axios.get(`${window.backendUrl}/get_all_categories`);
            // const response5 = await axios.get(`${window.backendUrl}/get_all_specifications'); use after making specifications in Manage Data

            const options1 = response1.data.data.map((team) => team['Name']);
            const options2 = response2.data.data.map((party) => party['PartyName']);
            const options3 = response3.data.data.map((buyer) => buyer['Name']);
            const options4 = response4.data.data.map((item) => item['Name']);
            const options5 = response5.data.data.map((category) => category['Category']);
            // const options5 = response.data.map((spec) => spec['Name']); use after making specifications in Manage Data
            set_team_options(options1);
            set_party_options(options2);
            set_buyer_options(options3);
            set_item_options(options4);
            set_category_options(options5);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFormData) => ({ ...prevFormData, [name]: value }));
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setSearchClick(true);
        setCurrentPage(1);
    };

    const handleDateRangeChange = (value) => {
        if (value && value.length === 2) {
            setDateRange(value);
        } else {
            setDateRange([]);
        }
    };

    const fetchDataWithFilters =  (page, filters, dateRange, job_number, style) => {
        const { teamname, partyname, buyername, itemname, category, specification } = filters; 
        // const page = currentPage
        const start_date = dateRange[0] ? dateRange[0] : ''
        const end_date = dateRange[1] ? dateRange[1] : ''

        axios.get(`${window.backendUrl}/get_production_planning_orders`, {
            params: { teamname, partyname, buyername, itemname, category, specification, page, job_number, style, start_date, end_date}
        })
        .then((response) => {
            // console.log(response.data);
            setOrders(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
            setSearchClick(false);
            response.data.data.forEach(order => {
                order.items.forEach(item => {
                    item.specifications.forEach(spec => {
                        spec.gsms.forEach(gsm => {
                            set_setting_and_roll(prevState => {
                                if (!prevState[gsm.gsm_id]) {
                                    return {
                                        ...prevState,
                                        [gsm.gsm_id]: {
                                            roll_size: gsm.roll_size,
                                            setting: gsm.setting 
                                        }
                                    };
                                }
                                return prevState;
                            });
                        });
                    });
                });
            });
        })
        .catch((error) => {
            console.log(error);
        });
    };
    
    // Define the debounced fetch function
    const debouncedFetchData = useCallback(
        debounce((page, filters, dateRange, job_number, style) => {
            // console.log('Debounced fetch:', { page, filters, dateRange });
            fetchDataWithFilters(page, filters, dateRange, job_number, style);
        }, 300), // Adjust debounce delay
        []
    );
    
    useEffect(() => {
        // Trigger the debounced function
        debouncedFetchData(currentPage, filters, dateRange, job_number, style);
    
        // Cleanup to cancel any pending debounced calls
        return () => {
            debouncedFetchData.cancel();
        };
    }, [filters, currentPage,  searchClick, dateRange,, debouncedFetchData]); // Only trigger when these dependencies change

    const [selectedValues, setSelectedValues] = useState([]);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedValues(prevValues => {
          if (checked) {
            // Add value to selectedValues if checkbox is checked
            return [...prevValues, value];
          } else {
            // Remove value from selectedValues if checkbox is unchecked
            return prevValues.filter(item => item !== value);
          }
        });
    };

    const handle_add_to_pp = () => {
        // console.log(roll_sizes)

        axios.get(`${window.backendUrl}/get_add_to_pp_orders`, {
            params: { gsm_ids: selectedValues.join(',') }
        })
        .then((response) => {
            set_pp_orders(response.data.data);

            const newQuantities = response.data.data.reduce((acc, order) => {
                const { OrderGsmId, OrderQuantity, pp_qty } = order;
                acc[OrderGsmId] = OrderQuantity - pp_qty;
                return acc;
            }, {});
    
            set_new_pp_quantities(newQuantities);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    const delete_all_pp_orders = () => {
        setSelectedValues([]);
        set_pp_orders([]);
    }

    const delete_pp_order = (gsmId) => {
        setSelectedValues(prevValues => prevValues.filter(id => id !== gsmId.toString()));
        set_pp_orders(prevOrders => prevOrders.filter(order => order.OrderGsmId !== gsmId));
    };

    const handleSettingChange = (e, gsm) => {
        // Conversion function for units to centimeters
        const convertToCM = (value, unit) => {
            const conversionFactors = {
                Inch: 2.54,
                MM: 0.1,
                Yard: 91.44,
            };
            const convertedValue = value * (conversionFactors[unit] || 1);
            return parseFloat(convertedValue.toFixed(2));
        };
    
        const { name, value } = e.target;
        const id = parseInt(name, 10); 
        const setting = parseInt(value, 10);
    
        // Convert gsm.width and gsm.height to cm
        const widthInCM = convertToCM(gsm.width, gsm.unit);
        const heightInCM = convertToCM(gsm.height, gsm.unit);
    
        // Calculate roll size using the formula
        let rollSize = ((widthInCM + heightInCM) * setting) / 2.54;
    
        // Round up to the next even number if roll size is not even
        if (rollSize % 2 !== 0) {
            rollSize = Math.ceil(rollSize / 2) * 2;
        }
    
        // Update the roll size and setting in the state
        set_setting_and_roll(prevState => ({
            ...prevState,
            [name]: {
                roll_size: rollSize,  // Use calculated roll size
                setting: setting,
            }
        }));
       
        // Make an axios post request to update the roll size
        axios.post(`${window.backendUrl}/update_setting_and_roll_size`, {
            id: id,
            setting: setting,
            rollSize: rollSize
        })
        .then(response => {
            console.log('Roll size updated successfully:', response.data);
        })
        .catch(error => {
            console.error('There was an error updating the roll size:', error);
        });
    };
    
    
    const handle_new_pp_qty_change = (e) => {
        const { name, value } = e.target;
    
        const id = parseInt(name, 10);
        const new_pp_qty = value === '' ? 0 : parseInt(value, 10);
    
        set_new_pp_quantities(prevState => ({
            ...prevState,
            [id]: new_pp_qty 
        }));
    };


    const handle_save_prod_plan = () => {
        if (!pp_date) {
            return;
        }
        const productionPlanningDate = new Date(pp_date).getTime() / 1000;

        const requestData = pp_orders.map(order => {
          return {
            pp_qty: new_pp_quantities[order.OrderGsmId],  // Assuming pp_qty is stored in each order object
            pp_date: productionPlanningDate,
            OrderGsmId: order.OrderGsmId
          };
        });
      
        // Axios POST request to send all orders data
        axios.post(`${window.backendUrl}/save_production_plan`, requestData)
          .then(response => {
            console.log('Production plans saved successfully:', response.data);
            setSelectedValues([]);
            set_pp_orders([]);
            setCurrentPage(1)
            fetchDataWithFilters(currentPage)
            // Handle success if needed
          })
          .catch(error => {
            console.error('Error saving production plans:', error);
            // Handle error if needed
          });
    };

    const [showDateModal, setShowDateModal] = useState(false);

    // const handleGenerClick = async (orderId) => {
    //     // setSelectedOrderId(orderId);
    //     // setSelectedOrderStatus(order_status);
    //     setShowDateModal(true);
    // };
  
    const handleCloseDateModal = () => {
        setShowDateModal(false);
    };

    const generatePPExcel = async (excel_pp_date) => {
        try {
          const response = await axios.get(`${window.backendUrl}/generate_pp_excel`, {
            params: { excel_pp_date }, 
            responseType: 'blob', // Ensure we receive the file as a blob
          });
      
          // Create a downloadable file from the response
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'ProductionPlanning.xlsx'); // File name
          document.body.appendChild(link);
          link.click();
          link.remove();
        } catch (error) {
          console.error('Error generating Excel file:', error);
        }
      };

    return (
    <>
        <NavigationBar />
        <Container fluid>
            <Row style={{ marginTop: '25px',marginBottom:'10px' }}>
                <Col className='d-flex'>
                    <h4 >Production Planning</h4>
                    <Button style={{marginLeft:'20px', backgroundColor:'rgba(0,0,0,0.1)', textDecoration:'none', border:'0px', color: 'black'}} onClick={()=>setShowDateModal(true)}>Generate P.P excel file</Button>
                </Col>
                {/* <Col>
                    <Button>Generate Production Plan excel file</Button>
                </Col> */}
                <Col className="d-flex justify-content-end align-items-center">
                    <p style={{fontSize:'15px'}}>P.P Date : </p>
                    <Form.Group controlId="formBasicDate" >
                        <Form.Control type="date" onChange={(e) => set_pp_date(e.target.value)} style={{width:'150px',margin:'0 100px 0 20px'}} />
                    </Form.Group>
                </Col>
            </Row>
            <Row style={{ marginTop: '5px',marginBottom:'10px' }}>
                <Col>
                        <Table bordered striped condensed="true" style={{ fontSize: '14px' }}>
                            <thead className="cf">
                            <tr>
                                <th>SL</th>
                                <th>Date/ EDD</th>
                                <th>Job No.</th>
                                <th>Buyer/ Style No </th>
                                <th>Team</th>
                                <th>Company</th>
                                <th>Items</th>
                                <th>Spec.</th>
                                <th>LxWxH</th>
                                <th>LO-LI-CM-PM</th>
                                <th>Order Qty</th>
                                <th>F.Prod. Done</th>
                                <th>B.M. QTY</th>
                                <th>New PP Qty</th>
                                <th>Roll Size</th>
                                <th><Image src="/Images/dustbin_logo.jpg" alt="dustbin" className='dustbin_img' onClick={delete_all_pp_orders}/></th>
                            </tr>
                            </thead>
                                <tbody>
                                    {pp_orders.length>0 ? (pp_orders.map(order => (
                                            <tr key={order.OrderGsmId}>
                                                <td>{order.OrderId}</td>
                                                <td>{new Date(order.OrderDate * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')} <hr style={{border: '1px solid black',margin:'5px 0' }}/>{new Date(order.DeliveryDate * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}</td>
                                                <td>{order.JobNumber}</td>
                                                <td>{order.BuyerName}<hr style={{border: '1px solid black',margin:'5px 0' }}/>{order.Style}</td>
                                                <td>{order.TeamName}</td>
                                                <td>{order.PartyName}</td>
                                                <td>{order.ItemName}</td>
                                                <td>{order.Specification}</td>
                                                <td>{order.Measurement}</td>
                                                {/* <td style={{width:'150px'}}>{order.GsmInfo}</td> */}
                                                <td></td>
                                                <td>{order.OrderQuantity}</td>
                                                <td>{order.finished_qty}</td>
                                                <td>{order.pp_qty}</td>
                                                <td style={{textAlign:'center'}}><input type='number' name={order.OrderGsmId} value={new_pp_quantities[order.OrderGsmId]} onChange={handle_new_pp_qty_change} style={{width:'70px'}}/></td>
                                                <td>{setting_and_roll[order.OrderGsmId].roll_size}</td>
                                                <td><Image src="/Images/dustbin_logo.jpg" alt="dustbin" className='dustbin_img' onClick={() => delete_pp_order(order.OrderGsmId)}/></td>
                                            </tr>
                                    ))):(<tr><td colSpan="16" style={{ fontSize:'15px'}}>No orders have been added</td></tr>)}
                                    {pp_orders.length>0 && (
                                        <tr>
                                            <td colSpan='9' style={{textAlign:'right'}}><h5>Grand total :</h5></td>
                                            <td></td>
                                            <td>{pp_orders.reduce((total, order) => total + order.OrderQuantity, 0)}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td colSpan='2' style={{textAlign:'center'}}>
                                                <Button variant="primary" style={{width:'150px'}} onClick={handle_save_prod_plan}>
                                                    Save Prod. Plan
                                                </Button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                        </Table>
                </Col>
            </Row>
            <Row className='d-flex justify-content-between'style={{ marginTop: '15px',marginBottom:'10px' }}>
                <Col>
                    <h4>Add PP From Here</h4>
                </Col>
                <Col>
                    <DateRangePicker placeholder="Select Order Date Range" onChange={handleDateRangeChange}/>
                </Col>
                <Col>
                    {renderPagination({ totalPages, currentPage, setCurrentPage })}
                    {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                </Col>
            </Row>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                    <Form className='d-flex justify-content-around'>
                        <Form.Select style={{width:'180px'}} name="teamname" value={filters.teamname} onChange={handleInputChange}>
                            <option value="">All Teams</option>
                            {team_options.map((team, index) => (
                                <option key={index} value={team}>
                                {team}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Select style={{width:'180px'}} name="partyname" value={filters.partyname} onChange={handleInputChange}>
                            <option value="">All Parties</option>
                            {party_options.map((party, index) => (
                                <option key={index} value={party}>
                                {party}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Select style={{width:'180px'}} name="buyername" value={filters.buyername} onChange={handleInputChange}>
                            <option value="">All Buyers</option>
                            {buyer_options.map((buyer, index) => (
                                <option key={index} value={buyer}>
                                {buyer}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Select style={{width:'180px'}} name="itemname" value={filters.itemname} onChange={handleInputChange}>
                            <option value="">All Items</option>
                            {item_options.map((item, index) => (
                                <option key={index} value={item}>
                                {item}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Select  style={{width:'180px'}} name="category" value={filters.category} onChange={handleInputChange}> 
                            <option value="">All Categories</option>
                                {category_options.map((category, index) => (
                                    <option key={index} value={category}>
                                    {category}
                                    </option>
                                ))}
                        </Form.Select>
                        <Form.Select style={{width:'180px'}} name="specification" value={filters.specification} onChange={handleInputChange}>
                            <option value="">All specification</option>
                            {spec_options.map((team, index) => (
                                <option key={index} value={team}>
                                {team}
                                </option>
                            ))}
                        </Form.Select>
                        
                        <Form.Control style={{width:'180px'}} type="text" name="style" value={style} onChange={(e) => set_style(e.target.value)} placeholder='Style/ LPD PO No.' />

                        <div className="search_container" style={{width:'180px'}} >
                            <Form.Control type="text" name="job_number" value={job_number} onChange={(e) => set_job_number(e.target.value)} placeholder='Job No.' />
                            <div onClick={handleSearch}><i className="fa fa-search"></i></div>
                        </div>
                    </Form>
            </Row>
            <Row>
                    <Col>
                        <Table bordered striped condensed="true" style={{ fontSize: '12px' }}>
                            <thead className="cf" style={{ fontSize: '14px' }}>
                                <tr>
                                    <th>SL</th>
                                    <th>Date/ EDD</th>
                                    <th>Job No.</th>
                                    <th>Style/LPD PO No. </th>
                                    <th>Team</th>
                                    <th>Company/Buyer</th>
                                    <th>Items</th>
                                    <th>Spec.</th>
                                    <th>Measurement</th>
                                    {/* <th>LO-LI-CM-PM</th> */}
                                    <th>Order Qty</th>
                                    <th>F.Prod. Done</th>
                                    <th>BM (Bal)<br/> Qty/WIP</th>
                                    <th>Settings and <br/>Roll Size</th>
                                    <th>
                                        Action<br/>
                                        {/* <Button  className='add_to_pp_button' onClick={() => set_add_to_pp_click(true)} > */}
                                        <Button  className='add_to_pp_button' onClick={handle_add_to_pp} >
                                            Add to PP
                                        </Button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order, orderIndex) => {
                                    let totalOrderQuantity = 0;
                                    let totalFinishedQty = 0;
                                    let totalWIPQty = 0;
                                    // let totalPrice = 0;
                                    const categoryTotals = {};

                                    const orderRows = order.items.map((item, itemIndex) => (
                                        item.specifications.map((spec, specIndex) => (
                                            spec.gsms.map((gsm, gsmIndex) => {
                                                totalOrderQuantity += gsm.orderQuantity;
                                                totalFinishedQty += gsm.finished_qty;
                                                totalWIPQty += (gsm.pp_qty - gsm.finished_qty)
                                                // totalPrice += gsm.orderQuantity * parseFloat(gsm.unitPrice);
                                                const rowSpanLength= order.items.reduce((acc, item) => acc + item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0), 0) + 1

                                                // Calculate category totals
                                                if (!categoryTotals[item.item_category]) {
                                                    categoryTotals[item.item_category] = {
                                                    totalOrderQuantity: 0,
                                                    };
                                                }
                                                categoryTotals[item.item_category].totalOrderQuantity += gsm.orderQuantity;

                                                return (
                                                    <tr key={`${orderIndex}-${itemIndex}-${specIndex}-${gsmIndex}`} >
                                                        {gsmIndex === 0 && specIndex === 0 && itemIndex === 0 && (
                                                            <>
                                                                <td rowSpan={rowSpanLength}>{order.serial_no}<hr style={{border: '1px solid black' }}/></td>
                                                                <td rowSpan={rowSpanLength}>{new Date(order.order_date * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}<hr style={{border: '1px solid black' }}/>{new Date(order.delivery_date * 1000).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Dhaka' }).replace(/\//g, '.')}</td>
                                                                <td rowSpan={rowSpanLength}>{order.jobNo}</td>
                                                                <td  style={{ width: '230px', wordWrap: 'break-word', wordBreak: 'break-word', overflowWrap: 'break-word' }} rowSpan={rowSpanLength}>{order.style}<hr style={{border: '1px solid black' }}/>{order.lpd_po_no}</td>
                                                                <td rowSpan={rowSpanLength}>{order.team}</td>
                                                                <td rowSpan={rowSpanLength}>{order.party_name}<hr style={{border: '1px solid black' }}/>{order.buyer_name}</td>                            
                                                            </>
                                                        )}
                                                        {gsmIndex === 0 && specIndex === 0 && (
                                                            <>
                                                            <td style={{verticalAlign:'middle'}} rowSpan={item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0)}>{item.item_name}</td>
                                                            </>
                                                        )}
                                                        {gsmIndex === 0 && (
                                                            <td style={{verticalAlign:'middle'}} rowSpan={spec.gsms.length}>{spec.specification}</td>
                                                        )}
                                                        <td>{gsm.measurement}</td>
                                                        {/* <td style={{width: "150px"}}>{gsm.gsm_info}</td> */}
                                                        {/* <td></td> */}
                                                        <td>{gsm.orderQuantity}</td>
                                                        <td>{gsm.finished_qty}</td>
                                                        <td>{gsm.pp_qty} ({gsm.orderQuantity - gsm.pp_qty}) <hr style={{border: '1px solid black' }}/>{gsm.pp_qty-gsm.finished_qty}</td>
                                                        <td className='roll_size_td' style={{textAlign:'center'}}>Roll Size : {setting_and_roll[gsm.gsm_id].roll_size}<hr style={{border: '1px solid black', margin:'10px 0' }}/>
                                                        {/* <Form.Select style={{ width: '140px' }}  name={gsm.gsm_id} value={roll_sizes[gsm.gsm_id]} onChange={handleRollSizeChange}> */}
                                                        Choose setting : <br/>
                                                        <Form.Select style={{ width: '90px'}}  name={gsm.gsm_id} value={setting_and_roll[gsm.gsm_id].setting} onChange={(e) => handleSettingChange(e,gsm)}>
                                                               {setting_options.map((option, index) => (
                                                                    <option key={index} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                        </Form.Select>
                                                        </td>
                                                        <td style={{textAlign:'center'}}><input type="checkbox" value={gsm.gsm_id} onChange={handleCheckboxChange} checked={selectedValues.includes(gsm.gsm_id.toString())}/></td>
                                                    </tr>
                                                );
                                            })
                                        ))
                                    ));

                                    return (
                                        <>
                                            {orderRows}
                                            <tr className='green_row'>
                                                <td colSpan="3" style={{textAlign: 'right'}}>
                                                    <strong>
                                                        Job Total:{" "}
                                                        {Object.keys(categoryTotals)
                                                        .map(category => `${category} (${categoryTotals[category].totalOrderQuantity})`)
                                                        .join(", ")}
                                                    </strong>
                                                </td>
                                                <td><strong>{totalOrderQuantity}</strong></td>
                                                <td><strong>{totalFinishedQty}</strong></td>
                                                <td><strong>{totalWIPQty}</strong></td>
                                                <td colSpan='2'></td>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                        </Table>
                        {/* <JobApprovalModal show={showModal} handleClose={handleCloseModal} handleConfirm={handleConfirmApprove} /> */}
                    </Col>
            </Row>
        </Container>
        <PPExcelDateModal show={showDateModal} handleClose={handleCloseDateModal} handleSave={generatePPExcel} />
    </>
    )
}

export default ProductionPlanning