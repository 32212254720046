import "rsuite/dist/rsuite.css";
import React, { useState, useEffect, useRef } from 'react';
import NavigationBar from '../NavigationBar';
import axios from '../axios';
import { Form, Container, Row, Col, Table, Button } from 'react-bootstrap';
import {renderPagination,renderAdvancedPagination, JobApprovalModal, ScheduleDateModal} from '../utilities';
import { DateRangePicker } from 'rsuite';
import './manage_orders.css';
import { useNavigate } from 'react-router-dom';
import Image from "react-bootstrap/Image";
// import ChallanPDFLink from "../PDFs/challan_pdf_link";
import { useCallback } from 'react';
import debounce from 'lodash/debounce';


function ManageDeliveryChallan() {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [orders, setOrders] = useState([]);
    const [job_number, set_job_number] = useState('');
    const [challan_no, set_challan_no] = useState('');
    const [searchClick, setSearchClick] = useState(false); 
    const [style, set_style] = useState('');
    const [filters, setFilters] = useState({
        teamname: '',
        partyname: '',
        buyername: '',
        itemname: '',
        specification: '',
    });
    const [dateRange, setDateRange] = useState([]);

    const [team_options, set_team_options]=useState([]);
    const [party_options, set_party_options]=useState([]);
    const [buyer_options, set_buyer_options]=useState([]);
    const [item_options, set_item_options]=useState([]);
    const [spec_options, set_spec_options]=useState(['ply 3','ply 5','ply 7']);

    useEffect(() => {
        fetch_dropdown_options();
    }, []);

    const fetch_dropdown_options = async () => {
        try {
            const response1 = await axios.get(`${window.backendUrl}/get_all_teams`);
            const response2 = await axios.get(`${window.backendUrl}/get_all_parties`);
            const response3 = await axios.get(`${window.backendUrl}/get_all_buyers`);
            const response4 = await axios.get(`${window.backendUrl}/get_all_items`);
            // const response5 = await axios.get(`${window.backendUrl}/get_all_specifications'); use after making specifications in Manage Data

            const options1 = response1.data.data.map((team) => team['Name']);
            const options2 = response2.data.data.map((party) => party['PartyName']);
            const options3 = response3.data.data.map((buyer) => buyer['Name']);
            const options4 = response4.data.data.map((item) => item['Name']);
            // const options5 = response.data.map((spec) => spec['Name']); use after making specifications in Manage Data
            set_team_options(options1);
            set_party_options(options2);
            set_buyer_options(options3);
            set_item_options(options4);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFormData) => ({ ...prevFormData, [name]: value }));
        setCurrentPage(1);
    };

    const handleSearch = () => {
        setSearchClick(true);
        setCurrentPage(1);
    };

    const handleDateRangeChange = (value) => {
        if (value && value.length === 2) {
            setDateRange(value);
        } else {
            setDateRange([]);
        }
    };

    const handleEditChallanClick = (challanId) => {
        navigate('/change-delivery-challan', { state: {challanId } });
    };

    const fetchDataWithFilters = (page, filters, dateRange, job_number, style, challan_no) => { 
        const { teamname, partyname, buyername, itemname, specification} = filters; 
        // const page = currentPage
        const start_date = dateRange[0] ? dateRange[0] : ''
        const end_date = dateRange[1] ? dateRange[1] : ''

        axios.get(`${window.backendUrl}/get_all_delivery_challans`, {
            params: { teamname, partyname, buyername, itemname, specification, page, job_number, style, challan_no, start_date, end_date}
        })
        .then((response) => {
            // console.log(response.data.data);
            setOrders(response.data.data);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);
            setSearchClick(false);
        })
        .catch((error) => {
            console.log(error);
        });
    };

    // Define the debounced fetch function
    const debouncedFetchData = useCallback(
        debounce((page, filters, dateRange, job_number, style, challan_no) => {
            // console.log('Debounced fetch:', { page, filters, dateRange });
            fetchDataWithFilters(page, filters, dateRange, job_number, style, challan_no);
        }, 300), // Adjust debounce delay
        []
    );

    useEffect(() => {
        // Trigger the debounced function
        debouncedFetchData(currentPage, filters, dateRange, job_number, style, challan_no);

        // Cleanup to cancel any pending debounced calls
        return () => {
            debouncedFetchData.cancel();
        };
    }, [filters, currentPage, searchClick, dateRange, debouncedFetchData]); // Only trigger when these dependencies change
    


    return (
    <>
        <NavigationBar />
        <Container fluid>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Col>
                <h4>Manage Delivery Challan</h4>
                </Col>
                <Col>
                    <Button style={{backgroundColor:'rgba(0,0,0,0.1)',textDecoration:'none',border:'0px',marginRight:'10px', color:'black'}} onClick={()=>navigate("/new-delivery-challan")}>
                        New Delivery Challan
                    </Button>
                    <DateRangePicker placeholder="Select Challan Date Range" onChange={handleDateRangeChange}/>
                </Col>
                <Col>
                    {renderPagination({ totalPages, currentPage, setCurrentPage })}
                    {renderAdvancedPagination({ totalPages, currentPage, setCurrentPage })}
                </Col>
            </Row>
            <Row style={{ marginTop: '20px',marginBottom:'10px' }}>
                <Form className='d-flex flex-wrap justify-content-around'>
                    <Form.Select style={{width:'150px'}} name="teamname" value={filters.teamname} onChange={handleInputChange}>
                        <option value="">All Teams</option>
                        {team_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="partyname" value={filters.partyname} onChange={handleInputChange}>
                        <option value="">All Parties</option>
                        {party_options.map((party, index) => (
                            <option key={index} value={party}>
                            {party}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="buyername" value={filters.buyername} onChange={handleInputChange}>
                        <option value="">All Buyers</option>
                        {buyer_options.map((buyer, index) => (
                            <option key={index} value={buyer}>
                            {buyer}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="itemname" value={filters.itemname} onChange={handleInputChange}>
                        <option value="">All Items</option>
                        {item_options.map((item, index) => (
                            <option key={index} value={item}>
                            {item}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Select style={{width:'150px'}} name="specification" value={filters.specification} onChange={handleInputChange}>
                        <option value="">All specification</option>
                        {spec_options.map((team, index) => (
                            <option key={index} value={team}>
                            {team}
                            </option>
                        ))}
                    </Form.Select>
                    
                    <Form.Control style={{width:'150px'}} type="text" name="style" value={style} onChange={(e) => set_style(e.target.value)} placeholder='Style/ LPD PO No.' />

                    <Form.Control style={{width:'150px'}} type="text" name="challan_no" value={challan_no} onChange={(e) => set_challan_no(e.target.value)} placeholder='Challan No.' />

                    <div className="search_container" style={{width:'150px'}} >
                        <Form.Control type="text" name="job_number" value={job_number} onChange={(e) => set_job_number(e.target.value)} placeholder='Job No.' />
                        <div onClick={handleSearch}><i className="fa fa-search"></i></div>
                    </div>
                </Form>
            </Row>
            <Row>
                <Col>
                    <Table bordered striped condensed="true" style={{ fontSize: '13px', overflowY: 'auto'  }}>
                        <thead className="cf" style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                            <tr>
                                <th >ID</th>
                                <th rowspan="2">Challan#</th>
                                <th>Job#</th>
                                <th>Company</th>
                                <th rowspan="2">Items</th>
                                <th rowspan="2">Specif.</th>
                                <th>Measurement</th>
                                <th rowspan="2">Ord.<br/>Qty</th>
                                <th rowspan="2">Prod.<br/>Done</th>
                                <th colspan="2">Delivery Info</th>
                                <th colspan="2">Delivery Balance</th>
                                <th rowspan="2">Packet</th>
                                <th rowspan="2">Remarks</th>
                                <th>Vehicle</th>
                                <th rowspan="2">Deli. Station</th>
                            </tr>
                            <tr>
                                <th>Date</th>
                                <th>Style#</th>
                                <th>Buyer</th>
                                <th>LxWxH</th>
                                <th>Qty</th>
                                <th>Value</th>
                                <th>Qty</th>
                                <th>Value</th>
                                <th>Driver</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order, orderIndex) => {
                                let totalOrderQuantity = 0;
                                let totalPrice = 0;

                                const orderRows = order.items.map((item, itemIndex) => (
                                    item.specifications.map((spec, specIndex) => (
                                        spec.gsms.map((gsm, gsmIndex) => {
                                            totalOrderQuantity += gsm.orderQuantity;
                                            totalPrice += gsm.orderQuantity * parseFloat(gsm.unitPrice);
                                            const rowSpanLength = order.items.reduce((acc, item) => acc + item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0), 0);

                                            return (
                                                <tr key={`${orderIndex}-${itemIndex}-${specIndex}-${gsmIndex}`}>
                                                    {gsmIndex === 0 && specIndex === 0 && itemIndex === 0 && (
                                                        <>
                                                            <td rowSpan={rowSpanLength}>{order.serial_no}
                                                                <i style={{ marginLeft: '20px' }} className="print-button fa fa-print" onClick={() => window.open(`/challan-pdf-viewer/${order.challanId}`, '_blank')}></i>
                                                                
                                                                <hr style={{ border: '1px solid black' }}/>
                                                                {new Date(order.deliveryDate * 1000).toLocaleString('en-GB', { timeZone: 'Asia/Dhaka', day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\//g, '.')}
                                                            </td>
                                                            <td rowSpan={rowSpanLength}>{order.challan_no} | <span className='edit_challan_text' onClick={()=>{handleEditChallanClick(order.challanId)}}>Edit</span></td>
                                                            <td  style={{width: '300px', wordWrap: 'break-word', wordBreak: 'break-word', overflowWrap: 'break-word'}} rowSpan={rowSpanLength}>{order.jobNo}<hr style={{ border: '1px solid black' }}/>{order.style}</td>
                                                            <td rowSpan={rowSpanLength}>{order.partyName}<hr style={{ border: '1px solid black' }}/>{order.buyerName}</td>
                                                        </>
                                                    )}
                                                    {gsmIndex === 0 && specIndex === 0 && (
                                                        <>
                                                            <td style={{ verticalAlign: 'middle' }} rowSpan={item.specifications.reduce((acc, spec) => acc + spec.gsms.length, 0)}>{item.itemName}</td>
                                                        </>
                                                    )}
                                                    {gsmIndex === 0 && (
                                                        <td style={{ verticalAlign: 'middle' }} rowSpan={spec.gsms.length}>{spec.specification}</td>
                                                    )}
                                                    <td>{gsm.measurement}</td>
                                                    <td>{gsm.orderQuantity}</td>
                                                    <td >{gsm.finishedQty}</td>
                                                    <td>{gsm.deliveryQty}</td>
                                                    <td>{gsm.currency == '$' ? '$' : '৳'}{(gsm.deliveryQty * parseFloat(gsm.unitPrice)).toFixed(2)}</td>
                                                    <td>{gsm.deliveryBalance}</td>
                                                    <td>{gsm.currency == '$' ? '$' : '৳'}{(gsm.deliveryBalance * parseFloat(gsm.unitPrice)).toFixed(2)}</td>
                                                    <td>{gsm.packet_qty_sum}</td>
                                                    {/* New columns for remarks, vehicle, and delivery station */}
                                                    {gsmIndex === 0 && specIndex === 0 && itemIndex === 0 && (
                                                        <>
                                                            <td style={{ verticalAlign: 'middle' }} rowSpan={rowSpanLength}>{order.remarks}</td>
                                                            <td style={{ verticalAlign: 'middle' }} rowSpan={rowSpanLength}>{order.vehicleNo}<hr style={{ border: '1px solid black' }}/>{order.driverName}</td>
                                                            <td style={{ verticalAlign: 'middle' }} rowSpan={rowSpanLength}>{order.deliveryStation}</td>
                                                        </>
                                                    )}
                                                </tr>
                                            );
                                        })
                                    ))
                                ));

                                return orderRows;
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    </>
    )
}

export default ManageDeliveryChallan